import { logger } from '@jobandtalent/logger-js/lib/index';

import type { NextPageContext } from 'next';

function Error({ statusCode }: { statusCode: number }) {
  return (
    <p>
      {statusCode ? `An error ${statusCode} occurred on server` : 'An error occurred on client'}
    </p>
  );
}

Error.getInitialProps = ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  const jtLogger = logger({
    application: process.env.APP_NAME,
    source_code_commit_id: process.env.COMMIT_ID
  });

  jtLogger.error(`${res?.req.method} ${res?.req.url}`);

  return { statusCode };
};

export default Error;
